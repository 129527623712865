import { useEffect, useState } from 'react';

export const LOCAL_STORAGE_KEY = 'QNA-DEMO-STATE';

export function useLocalStorageState<TState>(uniqueKey: string, defaultValue: TState) {
  const appState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  const [value, setter] = useState<TState>(uniqueKey in appState ? appState[uniqueKey] : defaultValue);
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...appState, [uniqueKey]: value }));
  }, [value]);
  return [value, setter] as const;
}
